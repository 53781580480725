import React, { useRef } from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import useLockBodyScroll from 'components/hooks/useLockBodyScroll';
import { Portal } from 'react-portal';
import useOnClickOutside from 'components/hooks/useOnClickOutside';
import ModalWrapper from './ModalWrapper';

const Modal = ({
  className,
  children,
  closed,
  visible,
  close,
  closeButton,
  closeIconInModal,
  outerClassName,
  dialogClassName,
  closeOnClickOutside,
}) => {
  useLockBodyScroll(visible);

  const ref = useRef();
  useOnClickOutside(ref, closeOnClickOutside ? close : () => undefined);

  return visible ? (
    <Portal>
      <ModalWrapper closeIconInModal={closeIconInModal} className={outerClassName}>
        <div
          role="dialog"
          aria-hidden={visible}
          aria-labelledby="modal__title"
          ref={ref}
          className={clsx('dialog', dialogClassName)}
        >
          <div className={`modal ${className}`}>
            {closeIconInModal && closed ? closeButton : null}
            {children}
          </div>

          {!closeIconInModal && closed ? closeButton : null}
        </div>
      </ModalWrapper>
    </Portal>
  ) : null;
};

Modal.defaultProps = {
  closed: true,
  className: '',
  closeIconInModal: false,
  closeButton: '',
  outerClassName: null,
  dialogClassName: null,
  closeOnClickOutside: true,
};

Modal.propTypes = {
  closed: PropTypes.bool,
  className: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  closeButton: PropTypes.node,
  closeIconInModal: PropTypes.bool,
  outerClassName: PropTypes.string,
  dialogClassName: PropTypes.string,
  closeOnClickOutside: PropTypes.bool,
};

export default Modal;
