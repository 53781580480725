import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';
import { parseCookies } from 'nookies';
import { BsChevronRight, BsX } from 'react-icons/bs';

import Logo from 'components/Logo/Logo';
import Modal from 'components/Modal';
import Text from 'components/Text/Text';
import Title from 'components/Title/Title';
import useClickOutSide from 'components/hooks/useClickOutSide';
import { useClientsOfUserQuery } from '@schema';
import useSwitchOrganisation from 'components/hooks/useSwitchOrganisation';

const ModalMultiOrga = ({ openModal, setOpenModal, t, onClose, hideCurrentOrga }) => {
  const ref = useRef(null);
  const [switchOrganisation] = useSwitchOrganisation();
  useClickOutSide(() => openModal && onClose?.(), ref);
  const { data: dataClients } = useClientsOfUserQuery();
  const { client_id: currentClientId } = parseCookies();
  const clients = hideCurrentOrga
    ? dataClients?.clientsOfUser?.filter(({ clientId }) => clientId !== currentClientId)
    : dataClients?.clientsOfUser;

  if (!clients) return null;
  const handleSwitch = async (clientId) => {
    await switchOrganisation({ clientId });
    setOpenModal(false);
  };

  return (
    <Modal className="!min-h-0" visible={openModal} close={() => setOpenModal(false)}>
      <div className="h-fit-content m-auto w-96 bg-white drop-shadow-xl" ref={ref}>
        <BsX
          className="absolute top-2 right-2 cursor-pointer text-3xl text-gray-500"
          onClick={onClose}
        />
        <Title data-cy="orga-title" size="h4" className="my-10 ml-4 text-center">
          {t('user-organisations.choice-organisation')}
        </Title>
        {clients.map((client, index) => (
          <div
            data-cy={`orga-${index}`}
            key={client.clientId}
            className="flex h-20 items-center justify-between border-t border-solid border-gray-500 p-4"
            onClick={() => handleSwitch(client.clientId)}
            onKeyDown={() => handleSwitch(client.clientId)}
            role="button"
            tabIndex="0"
          >
            <div className="flex items-center gap-4">
              <Logo
                clientId={client.clientId}
                providerSlug={client.providerInfo?.slug}
                size="small"
              />
              <Text>{client.providerInfo?.name || client.name}</Text>
            </div>
            <BsChevronRight className="text-3xl" />
          </div>
        ))}
      </div>
    </Modal>
  );
};

ModalMultiOrga.defaultProps = {
  onClose: () => null,
  hideCurrentOrga: false,
};

ModalMultiOrga.propTypes = {
  t: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  hideCurrentOrga: PropTypes.bool,
};

export default withTranslation('cart')(ModalMultiOrga);
