import { useEffect } from 'react';

function useLockBodyScroll(deps) {
  useEffect(() => {
    if (deps) {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      const originalStyleHtml = window.getComputedStyle(document.documentElement).overflow;
      const headerElement = document.getElementsByTagName('header');
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';

      if (headerElement[0])
        return () => {
          document.body.style.overflow = originalStyle;
          document.documentElement.style.overflow = originalStyleHtml;
          headerElement[0].style.width = '100%';
          document.body.style.width = '100%';
        };

      return () => {
        document.body.style.overflow = originalStyle;
        document.documentElement.style.overflow = originalStyleHtml;
        document.body.style.width = '100%';
      };
    }

    return () => undefined;
  }, [deps]);
}

export default useLockBodyScroll;
