import React, { useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useRouter } from 'next/router';
import { destroyCookie, setCookie } from 'nookies';
import ModalMultiOrga from 'components/ModalMultiOrga/ModalMultiOrga';
import useSignout from 'components/hooks/useSignout';
import useSwitchOrganisation from 'components/hooks/useSwitchOrganisation';

import useLanguage from 'components/hooks/useLanguage';
import LOGIN_MUTATION from 'graphql/mutations/login.graphql';
import UPDATE_LAST_CONNECTION from 'graphql/mutations/updateLastConnection.graphql';
import QUERY_ME from 'graphql/queries/getBasicUserInfo.graphql';
import GET_URL_COLOR_CSS_QUERY from 'graphql/queries/getUrlColorCss.graphql';
import CLIENTS_OF_USER from 'graphql/queries/clientsOfUser.graphql';
import useSuperAdminLogin from './useSuperAdminLogin';

export default function useSignin({ onError }) {
  const signout = useSignout();
  const router = useRouter();
  const client = useApolloClient();
  const { setLanguage } = useLanguage();
  const [openMultiOrga, setOpenMultiOrga] = useState(false);
  const [switchOrganisation] = useSwitchOrganisation();
  const superAdminLogin = useSuperAdminLogin();

  const [login] = useMutation(LOGIN_MUTATION);
  const [updateLastConnection] = useMutation(UPDATE_LAST_CONNECTION);

  const redirectAfterLogin = router.query.redirectTo || '/';

  const sendLoginGAInfo = async (userId, clientId, salespointId) => {
    window.gtag?.('event', 'login', {
      event_category: 'login',
      event_action: 'loginSuccess',
      user_id: userId,
      dimension1: clientId,
      dimension2: userId,
      dimension3: salespointId,
    });
  };

  const handleSignInResponse = async ({ clientId, jwToken }) => {
    await destroyCookie(null, 'client_id', { path: '/' });
    await setCookie(null, 'jwToken', jwToken, { secure: true, path: '/' });
    await updateLastConnection();
    const [
      { data: userData },
      {
        data: { clientsOfUser },
      },
    ] = await Promise.all([
      client.query({
        query: QUERY_ME,
        fetchPolicy: 'no-cache',
      }),
      client.query({
        query: CLIENTS_OF_USER,
        fetchPolicy: 'network-only',
      }),
      client.query({
        query: GET_URL_COLOR_CSS_QUERY,
        fetchPolicy: 'network-only',
      }),
    ]);

    const userDetails = userData?.me || {};
    const { id: userId, selectedSalesPointId: salespointId, languagePreference } = userDetails;
    const mail = userData?.me.email.toLowerCase();

    if (mail.includes('hs-qa') || mail.includes('qa-hs')) {
      await setCookie(null, 'qaButtonEnabled', jwToken, { secure: true, path: '/' });
    } else {
      await destroyCookie(null, 'qaButtonEnabled', { path: '/' });
    }

    await Promise.all([
      setLanguage(languagePreference),
      sendLoginGAInfo(userId, clientId, salespointId),
    ]);
    if (clientId) {
      // only used in autologin context
      if (userData.me.role.permissions.includes('hyp.super-admin.all')) {
        await superAdminLogin({
          clientId,
          roleSlug: 'admin',
          redirectTo: redirectAfterLogin,
        });
      } else {
        await switchOrganisation({ clientId });
      }
    } else if (clientsOfUser.length > 1) {
      setOpenMultiOrga(true);
    } else {
      await setCookie(null, 'client_id', clientId || clientsOfUser[0].clientId, {
        secure: true,
        path: '/',
      });

      router.push(redirectAfterLogin);
    }
  };

  const signIn = async ({ email, password, clientId }) => {
    await Promise.all([
      destroyCookie(null, 'client_id'),
      destroyCookie(null, 'superAdminJWT'),
      destroyCookie(null, 'jwToken'),
    ]);
    try {
      const { data } = await login({
        variables: {
          email,
          password,
        },
      });

      if (data.login.jwToken) {
        await handleSignInResponse({ ...data.login, clientId });
      }
    } catch (errors) {
      await onError?.(errors);
    }
  };

  return {
    signIn,
    handleSignInResponse,
    MultiModaleOrga: (
      <ModalMultiOrga
        openModal={openMultiOrga}
        setOpenModal={setOpenMultiOrga}
        onClose={async () => {
          setOpenMultiOrga(false);
          await signout();
          router.push(redirectAfterLogin);
        }}
      />
    ),
  };
}
