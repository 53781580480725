import styled from 'styled-components';
import devices from 'tokens/devices';

const Modal = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 20, 20, 0.7);
  top: 0;
  z-index: 9999;
  overflow-y: auto;
  padding-top: 126px;

  .dialog {
    height: 100%;
  }

  .media-details {
    justify-content: center;
  }

  .modal-container {
    height: 290px;
    width: 453px;
    background-color: ${({ theme }) => theme.colors.white600};
  }

  .modal {
    display: flex;

    &__popin {
      height: 308px;
      width: 452px;
      margin: 0 auto;
      padding: 24px;
      background-color: ${({ theme }) => theme.colors.white};
      display: flex;
      flex-direction: column;
      align-items: center;

      &-title {
        margin: 32px auto 0;
      }

      &-title-delete-file {
        margin: 0;
      }

      &-element {
        background-color: ${(props) => props.theme.colors.danger200};
        color: ${(props) => props.theme.colors.danger700};
        padding: 0.5rem;
        border-radius: 4px;
        font-size: 1.2rem;
        font-weight: 600;
        max-width: max-content;
        word-break: break-all;
      }

      &-description {
        padding: 0 50px;
        text-align: center;
        border-top: 1px solid ${({ theme }) => theme.colors.grey400};
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
        padding: 32px;
        margin: 32px auto 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
      }

      &-btn {
        display: flex;
        align-self: flex-end;

        &__continue {
          width: 123px;
          margin-left: 16px;
          text-transform: capitalize;
        }

        &__reset {
          width: 106px;
          text-transform: capitalize;
        }
      }
    }

    &__popin-order {
      margin: 0 auto;
      width: 590px;
      height: 340px;
      background-color: ${({ theme }) => theme.colors.white};
      padding: 50px 34px 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      &-description {
        border-top: 1px solid ${({ theme }) => theme.colors.grey400};
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
        padding: 30px 11px;
        text-align: center;
        font-weight: 400;
        line-height: 24px;
      }

      &-btn {
        display: flex;
        align-self: flex-end;

        &__continue {
          margin-left: 42px;
        }
      }
    }

    &__library {
      margin: 60px auto;
      width: 500px;
      height: 340px;
    }
  }

  .modal-delete {
    height: 100%;

    .title {
      text-align: center;
    }
  }

  .modal-publish {
    height: 100%;

    .title {
      text-align: center;
    }
  }

  .modal__zoom {
    margin: 66px auto;
    min-height: calc(100vh + 152px);

    img {
      width: 874px;
    }
  }
  .icon-close {
    font-size: 30px;
    margin-right: 20px;
    color: ${({ theme }) => theme.colors.danger500};
  }

  .product__carousel-close {
    .icon-close {
      position: absolute;
      margin: 0;
      top: 140px;
      right: 50px;
      font-size: 34px;
      color: ${({ theme }) => theme.colors.white};
      ::before {
        font-size: 34px;
      }
    }
  }

  .import__modal {
    background: white;
    max-height: 500px;
    width: 435px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    &-content {
      padding: 0 50px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      &-details {
        display: flex;
        justify-content: space-between;
        .import__modal-title {
          border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
          height: 52px;
          width: 100%;
        }
        .button-close-modal {
          color: var(--primary-500);
          font-size: 35px;
        }
      }

      .import__modal-data {
        overflow: auto;
        margin-top: 20px;
        height: 300px;
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
        &-text {
          padding-bottom: 10px;
        }
      }

      .import__modal-button {
        justify-content: flex-end;
        display: flex;
        margin-top: 20px;
        &-submit {
          width: 103px;
        }
        &-cancel {
          margin-right: 20px;
        }
      }
    }
  }

  @media ${devices.tablet} {
    .modal__popin-order {
      width: 488px;
    }
  }

  @media ${devices.mobile}, ${devices.xsmall} {
    margin-top: 78px;

    .modal {
      margin: 0;

      &__popin {
        width: 300px;
        &-title {
          margin: 0;
        }
        &-description {
          margin: 24px 0;
        }
      }
    }

    .modal__popin-order {
      width: 300px;
      height: 400px;
    }

    .product__carousel-close {
      .icon-close {
        top: 22px;
      }
    }
  }
`;

export default Modal;
