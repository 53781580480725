import { setCookie, parseCookies } from 'nookies';
import { useMutation } from '@apollo/react-hooks';
import { useRouter } from 'next/router';
import FORCE_SUPER_ADMIN_ROLE from 'graphql/mutations/forceSuperAdminRole.graphql';

export default function useSuperAdminLogin() {
  const router = useRouter();
  const [forceSuperAdminRole] = useMutation(FORCE_SUPER_ADMIN_ROLE);
  const handleForceSuperAdminRole = async ({
    roleSlug,
    clientId: inputClientId,
    providerId,
    redirectTo,
  }) => {
    const { superAdminJWT } = parseCookies();
    if (superAdminJWT) setCookie(null, 'jwToken', superAdminJWT, { secure: true, path: '/' });

    const {
      data: {
        forceSuperAdminRole: { jwToken, clientId },
      },
    } = await forceSuperAdminRole({
      variables: { roleSlug, clientId: inputClientId, providerId },
    });
    const { jwToken: currentToken } = parseCookies();
    setCookie(null, 'superAdminJWT', currentToken, { secure: true, path: '/' });
    setCookie(null, 'jwToken', jwToken, { secure: true, path: '/' });
    setCookie(null, 'client_id', clientId, { secure: true, path: '/' });
    if (redirectTo) router.push(redirectTo);
    else router.reload('/');
  };

  return handleForceSuperAdminRole;
}
